import React from "react"
import { Link } from "react-router-dom"

function List01({ reqType, items, parentId }) {
  // let description = item.description
  // description = description.slice(10)

  return (
    // <div className="row justify-content-center align-items-center mt-4">
    <>
      {items &&
        items.map((item) => {
          if (reqType === "product") {
            return (
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6" key={item._id}>
                <Link to={`/${reqType}/details?product=${parentId}&id=${item._id}`} className="learn-more">
                  <div className="feature-item wow fadeInUp delay-0-2s">
                    <div className="feature-content">
                      <p className="mb-4 feature-content-title text-center">{item.name}</p>
                      <p className="feature-content-text text-justify">{item.description.substring(0, 229)}</p>

                      <p className="learn-more">
                        Read More <i className="fas fa-arrow-right"></i>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            )
          }
          return (
            <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6" key={item._id}>
              <div className="feature-item wow fadeInUp delay-0-2s">
                <div className="feature-content">
                  <p className="mb-4 feature-content-title text-center">{item.name}</p>
                  <p className="feature-content-text text-justify">{item.description.substring(0, 299)}</p>
                </div>
              </div>
            </div>
          )
        })}
    </>
    // </div>
  )
}

export default List01
