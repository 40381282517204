import React from "react"
import Logo from "../../assets/images/logos/logo.png"

const PageLoader = () => (
  <div id="loading-spinner" className="d-flex justify-content-center align-items-center">
    <div className="">
      <div
        className="spinner-grow text-primary d-flex flex-column justify-content-center align-items-center"
        role="status"
        id="com-spinner"
      >
        <img alt="Company Logo" src={Logo} width={120} height={120} />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
)

export default PageLoader
