import React from "react"
import { Link } from "react-router-dom"
import background from "../../../assets/images/background/services.png"
import logo from "../../../assets/images/logos/logo.png"

export default function MainSection01({ reqType, items }) {
  return (
    <section
      className="services-seven rel text-center py-100 rpy-100"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          {items.length > 0 &&
            items.map((i) => {
              const url = i.files && i.files.length > 0 ? i.files[0].url : logo
              return (
                <div className="col-lg-4 col-sm-6" key={i.name}>
                  <div className="service-item-seven">
                    <Link to={`/${reqType}?id=${i._id}`}>
                      <img src={url} alt={i.name} height={200} />
                      <h3 className="text-head-title text-justify text-center">{i.name}</h3>
                    </Link>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}
