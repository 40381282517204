import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
function Navigation({ className }) {
  const { products } = useSelector((state) => state.companyList)
  return (
    <>
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/aboutus">About</Link>
          </li>
          <li>
            <Link to="/product">Products</Link>
            <ul className="sub-menu">
              {products.length > 0 &&
                products.map((p) => {
                  return (
                    <li key={p._id}>
                      <Link to={`/product?id=${p._id}`}>{p.name}</Link>
                    </li>
                  )
                })}
            </ul>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/brands">Brands</Link>
          </li>

          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Navigation
