import React from "react";
import Layouts from "./index";
import background2 from "../../../assets/images/background/brand-bg.jpg"

export default function ServicesLayout({ children, pageTitle, pageId }) {

  return (
    <Layouts pageTitle={pageTitle} pageId={pageId}>
      {children && children}
    </Layouts>
  )
}
