import React from "react"

const List02 = ({ items }) => {
  return (
    <>
      {items.map((i) => {
        if (i.value !== "") {
          return (
            <li className="justify-text" key={i._id}>
              <span className="text-uppercase d-inline">{i.name}: </span>
              <span className="ml-2 text-subhead-content d-inline">{i.value}</span>
            </li>
          )
        }
        return <div></div>
      })}
    </>
  )
}
export default List02
