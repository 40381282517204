import React from "react"

import Layouts from "../helpers/Layouts/index"
import FeatureSection from "./FeatureSection"

export default function ServiceOne() {
  return (
    <Layouts pageTitle="Services" pageId="brand-bg">
      <FeatureSection />
    </Layouts>
  )
}
