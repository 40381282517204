import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import companyList from "./companyList"
import rtlSetting from "./rtlSetting"

const rootReducer = combineReducers({
  rtl: rtlSetting.reducer,
  companyList: companyList.reducer,
})

// Configure the persist options
const persistConfig = {
  key: "root",
  storage,
}

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

// Create the persisted store
const persistor = persistStore(store)

export { persistor, store }

// export default configureStore({
//   reducer: {
//     rtl: rtlSetting,
//   },
// })
