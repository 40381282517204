import React from "react"
import { useSelector } from "react-redux"
import Layouts from "../helpers/Layouts"
import ServiceSection from "../helpers/sections/ServiceSection"
import AboutSection from "./AboutSection"
import WorkProcessSection from "./WorkProcessSection"

export default function About() {
  const { aboutus } = useSelector((state) => state.companyList)
  const { values } = aboutus
  return (
    <Layouts pageTitle="About Us" pageId="brand-bg">
      <AboutSection className="pb-240 r-com-about-section-two" />
      <WorkProcessSection />
      <ServiceSection items={values} title="our values" />
    </Layouts>
  )
}
