import React from "react"
import { Route, Routes } from "react-router-dom"
import AboutUs from "./webComponents/About/About"
import Contact from "./webComponents/Contact/index"
// import Home from "./components/HomeThree/Home";
import Brand from "./webComponents/Brands/Brand"
import Home from "./webComponents/Home/Home"
import Product from "./webComponents/Products/Product"
import ProductDetails from "./webComponents/Products/ProductDetails"
import Service from "./webComponents/Services/Service"

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/aboutus" element={<AboutUs />} />
      <Route exact path="/services" element={<Service />} />
      <Route exact path="/product" element={<Product />} />
      <Route exact path="/brands" element={<Brand />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/product/details" element={<ProductDetails />} />
    </Routes>
  )
}
