import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import Swal from "sweetalert2"
// import config from "../../config"

import useHttp from "../../hooks/useHttp"
import validate from "../../utalities/Validation"
import Layouts from "../helpers/Layouts"
import PageLoader from "../helpers/PageLoader"

export default function Contact() {
  const [formData, setFormData] = useState({ name: "", email: "", comments: "" })
  const { isLoading, error, sendRequest: sendHttpRequest } = useHttp()

  const reRef = useRef()
  // const MySwal = withReactContent(Swal)
  console.log(process.env.RECAPTCH_SECRET_KEY)

  const handleBlur = (e) => {
    const newData = { ...formData }
    newData[e.target.name] = e.target.value.trim()
    setFormData(newData)
  }

  useEffect(() => {
    if (error) {
      let mess
      if (error.response && error.response.data.message) {
        mess = error.response.data.message
      } else {
        mess = error.message
      }
      Swal.fire({
        title: mess,
        icon: "error",
        showCloseButton: true,
        // showCancelButton: true,
      })
    }
  }, [error])

  const getResponse = (result) => {
    if (result) {
      Swal.fire({
        title: "Message has been sent successfully.",
        icon: "success",
        showCloseButton: true,
        // showCancelButton: true,
      })
      setFormData({ name: "", email: "", comments: "" })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate(e, "comment-form")) {
      // try {
      const token = await reRef.current.executeAsync()
      reRef.current.reset()

      const reqConfig = {
        url: `https://api.next-upgrade.com/api/contactus/sendemail`,
        method: "post",
        body: { ...formData },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }

      sendHttpRequest(reqConfig, getResponse)
      // } catch (err) {
      //   console.log(err)
      // }
    }
  }
  return (
    <>
      {isLoading && <PageLoader />}
      {/* {toastBody.show && <SweetAlertDiv show={toastBody.show} message={toastBody.message} alertType={toastBody.type} />} */}
      {/* {toastBody.show && <Toastr show={toastBody.show} message={toastBody.message} alertType={toastBody.type} />} */}
      <ReCAPTCHA sitekey="6LfOkqMoAAAAADqIML-b3gN7eV1XgvJo-Ke86mNN" size="invisible" ref={reRef} />
      <Layouts pageTitle="Contact us" pageId="brand-bg">
        <section className="contact-page py-120 rpy-100">
          <div className="container">
            {/* <div className="contact-info-area mb-80">
              <div className="contact-info-item wow fadeInUp delay-0-2s">
                <i className="far fa-map"></i>
                <p>27 Division St, New York, NY 10002,USA</p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-4s">
                <i className="far fa-envelope"></i>
                <p>
                  <a href="mailto:support@gmail.com">support@gmail.com</a> <br />
                  <a href="#">www.restly.net</a>
                </p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-phone-alt"></i>
                <p>
                  <a href="callto:+08(964)712365">+08 (964) 712365</a> <br />
                  <a href="callto:+0234(456)9864">+0234 (456) 9864</a>
                </p>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s"
                  style={{
                    backgroundImage: `url("https://images.unsplash.com/photo-1586769852351-8791d2bb43be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fGVtYWlsfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60")`,
                  }}
                >
                  <h2>Send A Message</h2>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-form ml-40 rml-0 rmt-55 wow fadeInRight delay-0-2s">
                  <h3 className="comment-title mb-35">Send A Message</h3>
                  <form
                    id="comment-form"
                    className="comment-form mt-35 needs-validation"
                    name="comment-form"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="row clearfix justify-content-center">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            <i className="far fa-user"></i>
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            value={formData.name}
                            placeholder="Full Name"
                            onChange={handleBlur}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <i className="far fa-envelope"></i>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            value={formData.email}
                            placeholder="Email"
                            onChange={handleBlur}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="comments">
                            <i className="fas fa-pencil-alt"></i>
                          </label>
                          <textarea
                            name="comments"
                            id="comments"
                            className="form-control"
                            rows="4"
                            placeholder="Write Message"
                            onChange={handleBlur}
                            value={formData.comments}
                            required
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group mb-0">
                          <button type="submit" className="theme-btn">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  )
}
