import React from "react"
import { useSelector } from "react-redux"
import SliderCom from "../helpers/SliderCom"

export default function SponserSection() {
  const { clients } = useSelector((state) => state.companyList)
  const apiUrl = "https://api.next-upgrade.com"
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  return (
    <div className="client-logo-area" style={{ height: "14rem" }}>
      <div className="container">
        <div className="logo-carousel-wrap pt-65 pb-170">
          <h5 className="text-uppercase text-center text-head-title mb-5">our clients</h5>
          <SliderCom settings={settings}>
            {clients &&
              clients.length > 0 &&
              clients.map((c) => {
                return (
                  <div className="logo-item" key={c._id}>
                    <img
                      src={c.file && `${c.file.url}`}
                      // crossOrigin="anonymous"
                      alt={c.name}
                      height={50}
                      width={100}
                    />
                  </div>
                )
              })}
          </SliderCom>
        </div>
      </div>
    </div>
  )
}
