import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import useHttp from "../../hooks/useHttp"
import { companyListActions } from "../../store/companyList"
import Hero from "../helpers/Hero"
import PageLoader from "../helpers/PageLoader"
import Footer from "../partials/Footers/FooterHomeFour"
import Header from "../partials/Headers/HeaderHomeThree"
import ServiceSection from "./ServiceSection"
import ClientSection from "./SponserSection"
import VideoDiv from "../helpers/VideoDiv"

export default function Home() {
  const dispatch = useDispatch()
  const { isLoading, error, sendRequest: sendHttpRequest } = useHttp()

  const getResponse = (response) => {
    if (response) {
      const result = response.data.data
      let products = []
      let clients = []
      let brands = []
      let services = []
      let aboutus = {}
      let productList = []

      result.forEach((i) => {
        switch (i.element) {
          case "products":
            products = [...i.items]
            break
          case "clients":
            clients = [...i.items]
            break
          case "brands":
            brands = [...i.items]
            break
          case "services":
            services = [...i.items]
            break
          case "aboutus":
            aboutus = { ...i.items[0] }
            break
          case "productList":
            productList = [...i.items]
            break
          default:
            products = [...i.items]
        }
      })

      dispatch(
        companyListActions.replaceState({
          products,
          clients,
          brands,
          services,
          aboutus,
          productList,
        })
      )
    }
  }

  useEffect(() => {
    if (error) {
      const mess = error.response ? error.response.data.message : error.message
      // console.log(error.response.data.message)
      console.error(mess)
    }
  }, [error])

  useEffect(() => {
    const reqConfig = {
      url: `https://api.next-upgrade.com/api/companyInfo/all/items`,
      method: "get",
      headers: {
        "x-api-key": "ZmpqYnlkaDM2jjr887408NTUzJWpqZDcyNTg5ZG5qc2hmZ2JzZz0=",
      },
    }

    sendHttpRequest(reqConfig, getResponse)
  }, [])

  return (
    <>
      {isLoading && <PageLoader />}
      <Header />
      <VideoDiv/>
      {/* <Hero /> */}
      <ServiceSection />
      <ClientSection />
      <Footer />
    </>
  )
}
