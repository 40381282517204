import React from "react"
import logo from "../../../assets/images/logos/logo.png"

function ServiceSection({ items, title }) {
  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
      <div className="container">
        <div className="section-title text-center mb-35">
          <h2 className="text-head-title">{title}</h2>
        </div>
        <div className="row">
          {items.map((i) => {
            const imgUrl = i.file && i.file.url ? i.file.url : logo
            return (
              <div className="col-xl-3 col-sm-6 d-flex justify-content-center align-items-center" key={i._id}>
                <div className="service-box wow fadeInUp delay-0-2s">
                  <div className="service-normal text-center">
                    <div className="icon">
                      <img src={imgUrl} alt={i.title} width={100} height={100} />
                    </div>
                    <h5 className="text-subhead-title2">{i.title}</h5>
                  </div>
                  <div className="service-hover bg-red text-white">
                    <p>{i.details}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ServiceSection
