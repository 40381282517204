/* eslint-disable import/no-extraneous-dependencies */
// import { motion } from "framer-motion"
import React from "react"
import { useSelector } from "react-redux"
import logo from "../../assets/images/logos/logo.png"
import SliderCom from "../helpers/SliderCom"

export default function ServiceSection() {
  const { services } = useSelector((state) => state.companyList)
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <section className="services-eight rel z-1 pt-115 rpt-95 pb-120 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-10">
            <div className="section-title text-center mb-65">
              <h2 className="text-head-title">Our Services</h2>
            </div>
          </div>
        </div>
        <div className="service-slider-wrap">
          <SliderCom settings={settings}>
            {services &&
              services.length > 0 &&
              services.map((s) => {
                const imgUrl = s.file && s.file.url ? s.file.url : logo
                return (
                  <div className="service-item-eight wow fadeInUp delay-0-2s" id="change-width" key={s._id}>
                    <div className="icon mt-3">
                      <img src={imgUrl} alt={s.name} width={100} height={100} />
                    </div>
                    <h3 className="service-header-title">{s.name}</h3>
                    <p className="justify-text">{s.description.substring(0, 319)}</p>
                  </div>
                )
              })}
          </SliderCom>
        </div>
      </div>
    </section>
  )
}
