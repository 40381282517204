import React from "react"
import { useSelector } from "react-redux"
import Layout from "../helpers/Layouts/ServicesLayout"
import MainSection05 from "../helpers/sections/MainSection05"

export default function Brand() {
  const { brands } = useSelector((state) => state.companyList)
  return (
    <Layout pageTitle="Brands" pageId="brand-bg">
      {/* <MainSection reqType="brand" /> */}
      {/* <MainSection04 items={brands} /> */}
      <MainSection05 items={brands} />
    </Layout>
  )
}
