import React from "react"
import aboutThumb from "../../../assets/images/about/about-seven.png"
import List02 from "../List/List02"

export default function MainSection03({ item }) {
  const itemsList = [
    {
      name: "class",
      value: item.class,
      id: 0,
    },
    {
      name: "size",
      value: item.size,
      id: 1,
    },
    {
      name: "range",
      value: item.range,
      id: 2,
    },
    {
      name: "material",
      value: item.material,
      id: 3,
    },
  ]

  const imgUrl = item.file && item.file.url ? item.file.url : aboutThumb
  return (
    <section className="about-seven rel z-1 pt-150 rpt-30 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="about-seven-image rmb-60">
              <img src={imgUrl} alt={item.name} />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-seven-content">
              <div className="section-title mb-30">
                <h2 className="text-subhead-title" style={{ fontSize: "1.5rem" }}>
                  {item.name}
                </h2>
                <span className="sub-title justify-text">{item.productType}</span>
              </div>
              <p className="justify-text text-subhead-content">{item.description}</p>
              <ul className="list-style-three mt-35 mb-50">
                {itemsList && itemsList.length > 0 && <List02 items={itemsList} key={item._id} />}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-drop"></div>
    </section>
  )
}
