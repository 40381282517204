/* eslint-disable import/no-extraneous-dependencies */
import React from "react"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import List01 from "../List/List01"

export default function MainSection02({ item, reqType }) {
  const images = []
  if (item.files && item.files.length > 0) {
    item.files.forEach((file) => {
      images.push({
        original: file.url,
        thumbnail: file.url,
      })
    })
  }
  return (
    <>
      <section className="service-details pt-50 rpt-100 pb-100 rpb-80">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-12">
              <div className="service-details-content wow fadeInRight delay-0-2s">
                <h2 className="text-subhead-title mb-5">{item.name}</h2>
                <p className="justify-text text-subhead-content">{item.description}</p>
              </div>
              <div className="image mb-1 mt-5">
                {images && images.length > 0 && <ImageGallery items={images} lazyLoad />}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-eight rel z-1 pt-115 rpt-95 pb-120 rpb-100">
        {item.categories && item.categories.length > 0 && (
          <div className="container" key={item._id}>
            <div className="row justify-content-center align-items-center">
              <div className="text-center mb-4">
                <h5 className="text-subhead-title">Sub-product list</h5>
              </div>
            </div>
            <div className="row justify-content-center align-items-center mt-4">
              <List01 items={item.categories} reqType={reqType} parentId={item._id} />
            </div>
          </div>
        )}
      </section>
    </>
  )
}
