import axios from "axios"
import { useCallback, useState } from "react"

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true)
    setError(null)
    try {
      //   const formData = new FormData()
      //   if (requestConfig.body && requestConfig.body.file) {
      //     const fileResponse = await fetch(requestConfig.body.file.previewUrl)
      //     const blob = await fileResponse.blob()
      //     formData.append("id", requestConfig.body.id)
      //     formData.append("file", blob, requestConfig.body.file.name)
      //   }

      const response = await axios({
        method: requestConfig.method || "GET",
        url: requestConfig.url,
        data: requestConfig.body ? { ...requestConfig.body } : null,
        headers: requestConfig.headers || {},
      })

      if (response) {
        applyData(response.data)
      }
    } catch (err) {
      setError(err)
      console.log(err.message)
      // return err
    }
    setIsLoading(false)
  }, [])

  //   const sendRequestPost = useCallback(async (requestConfig, applyData) => {
  //     debugger
  //     setIsLoading(true)
  //     setError(null)
  //     try {

  //       const updateFile = await axios({
  //         method:
  //           (requestConfig.method && requestConfig.method.length >= 1 ? requestConfig.method[0] : requestConfig.method) ||
  //           "GET",
  //         url: requestConfig.url && requestConfig.url.length >= 1 ? requestConfig.url[0] : requestConfig.url,
  //         data: requestConfig.body && requestConfig.body >= 1 ? requestConfig.body[0] : null,
  //         headers: requestConfig.headers || {},
  //       })

  //       if (updateFile) {
  //         // applyData(updateFile.data.file)
  //         // console.log(response)
  //         let reqData = {}
  //         if (requestConfig.body && requestConfig.body >= 1) {
  //           reqData = requestConfig.body[1]
  //           delete reqData.clientFile
  //           reqData.file = updateFile.data.file._id
  //         }

  //         const response = await axios({
  //           method:
  //             (requestConfig.method && requestConfig.method.length >= 1
  //               ? requestConfig.method[1]
  //               : requestConfig.method) || "GET",
  //           url: requestConfig.url && requestConfig.url.length >= 1 ? requestConfig.url[1] : requestConfig.url,
  //           data: reqData || null,
  //           headers: requestConfig.headers || {},
  //         })

  //         applyData(updateFile.data)
  //         console.log(response)
  //       }
  //     } catch (err) {
  //       setError(err)
  //       console.log(err.message)
  //     }
  //     setIsLoading(false)
  //   }, [])

  return {
    isLoading,
    error,
    sendRequest,
    // sendRequestPost,
  }
}

export default useHttp
