/* eslint-disable jsx-a11y/media-has-caption */
import React from "react"
import defaultVideo from "../../assets/videos/default-video.mp4"


export default function VideoDiv() {


  return (
    <section className="main-slider bg-black text-white">
      <div className="main-slider-wrap position-relative d-flex justify-content-center align-items-center overflow-hidden">
        <video src={defaultVideo} autoPlay loop muted />

        <div className="video-content d-flex flex-column justify-content-center">
          <h2 className="text-uppercase vido-content-text" style={{ fontSize: "4rem" }}>
            we are
          </h2>
          <h1 className="text-uppercase vido-content-text" style={{ fontSize: "6rem" }}>
            incwic
          </h1>
          <h5 className="text-uppercase vido-content-text">
            we Sell all kind of Equipments to measure pressure, tempresture and water flow meters.
          </h5>
        </div>
      </div>
    </section>
  )
}
