import React from "react"
import Footer from "../../partials/Footers/FooterHomeFour"
import Header from "../../partials/Headers/HeaderHomeThree"
import Hero from "./Hero"

import "../../../assets/css/main.css"
import "../../../assets/css/style.css"

export default function Layouts({ children, pageTitle, pageId }) {
  return (
    <>
      <Header />
      <Hero pageTitle={pageTitle} pageId={pageId} />
      {children && children}
      <Footer />
    </>
  )
}
