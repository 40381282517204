import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import logo from "../../../assets/images/logos/logo.png"
import "./style.css"

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px")
  const { products } = useSelector((state) => state.companyList)
  const [item, setItem] = useState("")
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length
    if (getItems > 0) {
      if (item !== value) {
        // setSize(`${50 * getItems}px`);
        setItem(value)
      } else {
        setItem("")
      }
    }
  }
  return (
    <>
      <div onClick={(e) => action(e)} className={`off_canvars_overlay ${drawer ? "active" : ""}`}></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}>
                <Link to="/" className="offcanvas-brand text-center mb-40 d-block">
                  <img src={logo} alt="Company Logo" style={{ height: "10rem" }} />
                </Link>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li onClick={(e) => handler(e, "home")} id="home" className="menu-item-has-children active">
                      <Link to="/">Home</Link>
                    </li>
                    <li onClick={(e) => handler(e, "about")} id="about" className="menu-item-has-children active">
                      <Link to="/aboutus">About</Link>
                    </li>

                    <li onClick={(e) => handler(e, "product")} id="product" className="menu-item-has-children active">
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <Link to="/product">Products</Link>
                      <ul
                        className="sub-menu"
                        // style={{
                        //   height: "0px",
                        // }}
                      >
                        {products.length > 0 &&
                          products.map((p) => {
                            return (
                              <li key={p._id}>
                                <Link to={`/product?id=${p._id}`}>{p.name}</Link>
                              </li>
                            )
                          })}
                      </ul>
                    </li>
                    <li onClick={(e) => handler(e, "service")} id="service" className="menu-item-has-children active">
                      <Link to="/services">Services</Link>
                    </li>
                    <li onClick={(e) => handler(e, "brand")} id="brand" className="menu-item-has-children active">
                      <Link to="/brands">Brands</Link>
                    </li>
                    <li onClick={(e) => handler(e, "contact")} id="contact" className="menu-item-has-children active">
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Drawer
