import React from "react"
import { Link } from "react-router-dom"
import logo from "../../../../assets/images/logos/logo.png"

export default function index() {
  return (
    <footer className="main-footer footer-three bg-light-black text-white pt-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="footer-widget about-widget">
              <div className="footer-logo mb-35">
                <a href="/">
                  <img src={logo} alt="Logo" style={{ height: "5rem" }} />
                </a>
              </div>
              <div className="text text-uppercase">
                we Sell all kind of Equipments to measure pressure, tempresture and water flow meters.
              </div>

              <div className="social-style-two mt-30">
                <Link to="https://x.com/incwicgroup?s=11&t=IA0Uv_HEeOBJGOGrhMuFPg" target="_blank">
                  <i className="fab fa-twitter"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="footer-widget contact-widget mr-30 rmr-0">
              <h4 className="footer-title text-uppercase text">Contacts</h4>
              <ul className="list-style-two">
                <li className="text-capitalize">
                  <i className="fas fa-map-marker-alt"></i> Saudi Arabia - Riyadh - Al Amal- Omar Al Moukhtar
                </li>
                <li className="text-capitalize">
                  <i className="fas fa-clock "></i> Sun-Sat 8:00 AM- 1:00 PM & 4:00 PM - 8:00 PM
                </li>
                <li>
                  <i className="fas fa-phone-alt"></i>
                  <Link to="callto:+966540402227">+966540402227</Link>
                </li>
                <li className="text-capitalize">
                  <i className="fas fa-envelope"></i>
                  <Link to="mailto:instrumentation@incwic.com">instrumentation@incwic.com</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area bg-blue  text-center">
        <div className="d-flex justify-content-center align-items-center container text-center">
          <div className="copyright-inner text-center">
            <p className="text-capitalize">
              &copy; 2024 <span className="text-uppercase">incwic</span>.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
