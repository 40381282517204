import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  products: [],
  clients: [],
  brands: [],
  services: [],
  aboutus: {},
  productList: [],
}

const companyList = createSlice({
  name: "companyList",
  initialState,
  reducers: {
    replaceState(state, action) {
      state.products = action.payload.products
      state.clients = action.payload.clients
      state.brands = action.payload.brands
      state.services = action.payload.services
      state.aboutus = action.payload.aboutus
      state.productList = action.payload.productList
    },
  },
})

export const companyListActions = companyList.actions

export default companyList
