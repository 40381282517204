import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import Layouts from "../helpers/Layouts/index"
import MainSection03 from "../helpers/sections/MainSection03"

export default function ProductDetails() {
  const [searchParams] = useSearchParams()
  const subProductId = useMemo(() => searchParams.get("id"), [searchParams])
  const productId = useMemo(() => searchParams.get("product"), [searchParams])
  const { products } = useSelector((state) => state.companyList)
  let pageTitle = "Product"
  let product

  const pageNotFound = (
    <div className="service-details pt-50 rpt-100 pb-100 rpb-80 mb-5 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="text-uppercase">Page Not found !</h1>
          </div>
        </div>
      </div>
    </div>
  )

  let renderedSection

  if (productId && subProductId) {
 
    const mainProduct = products.find((p) => p._id === productId)
    product = mainProduct.categories.find((p) => p._id === subProductId)

    if (mainProduct && product) {
      renderedSection = <MainSection03 item={product} />
      pageTitle = product.name
    } else {
      renderedSection = pageNotFound
    }
  } else {
    renderedSection = pageNotFound
  }
  return (
    <Layouts pageTitle={pageTitle} pageId="brand-bg">
      {renderedSection}
    </Layouts>
  )
}
