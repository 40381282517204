import React, { useEffect } from "react"

// Todo: for spacific page rtl disabled
import { useDispatch, useSelector } from "react-redux"
import Routers from "./Routers"
import useToggle from "./hooks/useToggle"
import { rtlToggle } from "./store/rtlSetting"

function App() {
  const { rtl } = useSelector((state) => state.rtl.rtl)
  const dispatch = useDispatch()
  const html = document.getElementsByTagName("html")
  const [settingToggle, setToggle] = useToggle(false)
  // Todo: for spacific page rtl disabled
  // const location = useLocation();
  useEffect(() => {
    if (rtl) {
      html[0].dir = "rtl"
    } else {
      html[0].dir = ""
    }
    // Todo: for spacific page rtl disabled
    // if (location.pathname !== "/home-eight") {
    //   if (rtl) {
    //     html[0].dir = "rtl";
    //   } else {
    //     html[0].dir = "";
    //   }
    // }
  })

  return (
    <div className={rtl ? "direction-rtl" : ""}>
      <Routers />
      <div className={`layout-settings ${settingToggle ? "active" : ""}`}>
        <div className="layout-content">
          <ul>
            <li className="content-item">
              <div className="item-name">
                <span>RTL/LTR</span>
              </div>
              <div className={`item-button  ${rtl ? "active" : ""} `}>
                <button type="button" onClick={() => dispatch(rtlToggle())}>
                  <span></span>
                </button>
              </div>
            </li>
          </ul>
        </div>
        {/* <div className="close-btn-wrapper" onClick={() => setToggle.toggle()}>
          <span>
            <img src={logo} alt="Logo" style={{ width: "2rem" }} />
          </span>
        </div> */}
      </div>
    </div>
  )
}

export default App
