function validateForm(event, id) {
  const forms = document.getElementsByClassName("needs-validation")
  // Loop over them and prevent submission
  let isValid = true
  Array.prototype.filter.call(forms, (form) => {
    if (form.id === id) {
      form.classList.add("was-validated")
      if (form.checkValidity() === false) {
        form.classList.add("was-validated")
        // event && event.preventDefault()
        isValid = false
      } else {
        // event && event.preventDefault()
        // event && event.stopPropagation()
        isValid = true
      }
    }
  })

  return isValid
}

export default function validate(e, id) {
  const validForm = validateForm(e, id)

  return validForm
}
