import React from "react"

export default function WorkProcessSection05({ items }) {
  return (
    <section className="pricing-section pt-115 rpt-95 pb-90 rpb-70">
      <div className="container">
        {/* <div className="section-title text-center mb-35">
          <span className="sub-title">Pricing Packages</span>
          <h2>Choose Your Best Plan</h2>
        </div> */}
        <div className="row justify-content-center align-item-center">
          {items &&
            items.length > 0 &&
            items.map((i) => {
              return (
                <div className="col-lg-4 col-sm-6" key={i._id}>
                  <div className="pricing-item wow fadeInUp delay-0-2s">
                    <div className="pricing-header">
                      <h5 className="pricing-title">{i.name}</h5>
                    </div>
                    <ul>
                      <li className="justify-text">{i.description.substring(0, 299)}</li>
                    </ul>
                    {/* <a className="theme-btn btn-circle" href="#">
                Choose Plan
              </a> */}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}
