import React from "react"
import { useSelector } from "react-redux"
import logo from "../../assets/images/logos/logo.png"

function AboutSection({ className }) {
  const { aboutus } = useSelector((state) => state.companyList)
  const { description } = aboutus

  return (
    <section className={`about-section bg-light-black pt-120 rpt-100 ${className}`} id="about-section ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="about-image-shape rmb-70 wow fadeInLeft delay-0-2s text-center">
              <img src={logo} alt="About" width={230} />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-content text-white pr-70 rpr-0 wow fadeInRight delay-0-2s">
              <p className="justify-text">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
