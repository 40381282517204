import React, { useEffect } from "react"
import logo from "../../../../assets/images/logos/logo.png"
import StickyMenu from "../../../../lib/StickyMenu"
import Navigation from "../../../helpers/Navigation"
import MobileHeaderCom from "../../Mobile/MobileHeaderCom"

export default function HeaderHomeThree() {
  useEffect(() => {
    StickyMenu(".main-header")
  })
  return (
    <>
      <MobileHeaderCom logo={logo} />
      <header className="main-header header-three ">
        <div className="header-upper">
          <div className="container clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Company Logo" title="Logo" style={{ height: "4rem", width: "4.5rem" }} />
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <div className="main-menu navbar-expand-lg">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
