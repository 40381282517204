import React from "react"
import { useSelector } from "react-redux"
import List03 from "../helpers/List/List03"
import SliderCom from "../helpers/SliderCom"

export default function WorkProcessSection() {
  const { aboutus, clients } = useSelector((state) => state.companyList)
  const { vision, mission, values } = aboutus

  const items = [
    {
      name: "vision",
      description: vision,
      icon: "",
      id: 1,
    },
    {
      name: "mission",
      description: mission,
      icon: "",
      id: 2,
    },
  ]
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  return (
    <section className="work-progress-three bg-light text-default pb-85 rpb-65">
      <div className="container">
        <div className="logo-carousel-wrap style-two bg-white py-100 px-25 br-5">
          <div className="section-title text-center mb-35">
            {/* <span className="sub-title">Pricing Packages</span> */}
            <h5 className="text-head-title">our clients</h5>
          </div>
          <SliderCom settings={settings}>
            {clients &&
              clients.length > 0 &&
              clients.map((c) => {
                return (
                  <div className="logo-item" key={c._id}>
                    <img src={c.file && `${c.file.url}`} alt={c.name} width={100} />
                  </div>
                )
              })}
          </SliderCom>
        </div>
        <div className="work-progress-inner-three">
          <div className="row justify-content-center align-items-center">
            <List03 items={items} />
          </div>
        </div>
      </div>
    </section>
  )
}
