import React from "react"

const List03 = ({ items }) => {
  return (
    <>
      {items.map((i) => {
        const firstChar = i.name.charAt(0)
        return (
          <div className="col-lg-6 col-sm-6" key={i.id}>
            <div className="progress-item-two style-two wow fadeInUp delay-0-2s">
              <span className="progress-step text-uppercase">{firstChar}</span>
              <div className="icon">
                <i className="flaticon flaticon-speech-bubble"></i>
              </div>
              <h3 className="text-subhead-title">{i.name}</h3>
              <p className="justify-text text-subhead-content">{i.description}</p>
            </div>
          </div>
        )
      })}
    </>
  )
}
export default List03
