import React from "react"
import { useSelector } from "react-redux"
import List01 from "../helpers/List/List01"

function FeatureSection() {
  const { services } = useSelector((state) => state.companyList)
  return (
    <section className="featured-section bgs-cover pt-240 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {services && services.length > 0 && <List01 items={services} reqType="service" />}
        </div>
      </div>
    </section>
  )
}

export default FeatureSection
